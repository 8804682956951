/* This stylesheet generated by Transfonter (https://transfonter.org) on February 13, 2018 8:55 AM */

/* @font-face {
    font-family: 'Lato Hairline';
    src: url('Lato-Hairline.eot');
    src: local('Lato Hairline'), local('Lato-Hairline'),
        url('Lato-Hairline.eot?#iefix') format('embedded-opentype'),
        url('Lato-Hairline.woff') format('woff'),
        url('Lato-Hairline.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
} */

@font-face {
    font-family: 'Lato';
    src: url('Lato-Heavy.eot');
    src: local('Lato Heavy'), local('Lato-Heavy'),
        url('Lato-Heavy.eot?#iefix') format('embedded-opentype'),
        url('Lato-Heavy.woff') format('woff'),
        url('Lato-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-HeavyItalic.eot');
    src: local('Lato Heavy Italic'), local('Lato-HeavyItalic'),
        url('Lato-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('Lato-HeavyItalic.woff') format('woff'),
        url('Lato-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Regular.eot');
    src: local('Lato Regular'), local('Lato-Regular'),
        url('Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('Lato-Regular.woff') format('woff'),
        url('Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Black.eot');
    src: local('Lato Black'), local('Lato-Black'),
        url('Lato-Black.eot?#iefix') format('embedded-opentype'),
        url('Lato-Black.woff') format('woff'),
        url('Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-SemiboldItalic.eot');
    src: local('Lato Semibold Italic'), local('Lato-SemiboldItalic'),
        url('Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('Lato-SemiboldItalic.woff') format('woff'),
        url('Lato-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-BlackItalic.eot');
    src: local('Lato Black Italic'), local('Lato-BlackItalic'),
        url('Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Lato-BlackItalic.woff') format('woff'),
        url('Lato-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

/* @font-face {
    font-family: 'Lato Hairline';
    src: url('Lato-HairlineItalic.eot');
    src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'),
        url('Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
        url('Lato-HairlineItalic.woff') format('woff'),
        url('Lato-HairlineItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
} */

@font-face {
    font-family: 'Lato';
    src: url('Lato-MediumItalic.eot');
    src: local('Lato Medium Italic'), local('Lato-MediumItalic'),
        url('Lato-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Lato-MediumItalic.woff') format('woff'),
        url('Lato-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-ThinItalic.eot');
    src: local('Lato Thin Italic'), local('Lato-ThinItalic'),
        url('Lato-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Lato-ThinItalic.woff') format('woff'),
        url('Lato-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Thin.eot');
    src: local('Lato Thin'), local('Lato-Thin'),
        url('Lato-Thin.eot?#iefix') format('embedded-opentype'),
        url('Lato-Thin.woff') format('woff'),
        url('Lato-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Medium.eot');
    src: local('Lato Medium'), local('Lato-Medium'),
        url('Lato-Medium.eot?#iefix') format('embedded-opentype'),
        url('Lato-Medium.woff') format('woff'),
        url('Lato-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Italic.eot');
    src: local('Lato Italic'), local('Lato-Italic'),
        url('Lato-Italic.eot?#iefix') format('embedded-opentype'),
        url('Lato-Italic.woff') format('woff'),
        url('Lato-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Semibold.eot');
    src: local('Lato Semibold'), local('Lato-Semibold'),
        url('Lato-Semibold.eot?#iefix') format('embedded-opentype'),
        url('Lato-Semibold.woff') format('woff'),
        url('Lato-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Bold.eot');
    src: local('Lato Bold'), local('Lato-Bold'),
        url('Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('Lato-Bold.woff') format('woff'),
        url('Lato-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-BoldItalic.eot');
    src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
        url('Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Lato-BoldItalic.woff') format('woff'),
        url('Lato-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-LightItalic.eot');
    src: local('Lato Light Italic'), local('Lato-LightItalic'),
        url('Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Lato-LightItalic.woff') format('woff'),
        url('Lato-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Light.eot');
    src: local('Lato Light'), local('Lato-Light'),
        url('Lato-Light.eot?#iefix') format('embedded-opentype'),
        url('Lato-Light.woff') format('woff'),
        url('Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
