:root {
  // Project fonts, current
  --font-xxs: 12px;
  --font-xs: 14px;
  --font-sm: 16px;
  --font-md: 18px;
  --font-lg: 24px;
  --font-xl: 42px;

  // Project offsets, need update
  --offset-xs: 6px;
  --offset-sm: 10px;
  --offset-md: 16px;
  --offset-lg: 24px;
  --offset-xl: 36px;
}
